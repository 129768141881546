/*****************constants******************/
@import url("https://fonts.googleapis.com/css2?family=Cairo:wght@300;400;500&display=swap");
* {
  padding: 0;
  margin: 0;
  line-height: 30px;
  box-sizing: border-box;
  scroll-behavior: smooth;
  -webkit-tap-highlight-color: transparent;
  font-family: "Cairo", sans-serif;
}

body {
  background-color: #ffffff;
  overflow-x: hidden;
  direction: rtl;
}

ul {
  list-style: none;
}

a {
  text-decoration: none;
}

::-webkit-scrollbar {
  width: 3px;
}

::-webkit-scrollbar-track {
  background-color: #ffffff;
}

::-webkit-scrollbar-thumb {
  background: #a36709;
}

.btn {
  background: linear-gradient(268.08deg, #f1b048 0%, #a36709 100%);
  box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.25);
  color: #fff;
  border: none;
  padding: 0 20px;
  border-radius: 4px;
  cursor: pointer;
  height: -moz-fit-content;
  height: fit-content;
  width: -moz-fit-content;
  width: fit-content;
}

img {
  width: 100%;
  height: 100%;
  display: block;
  -o-object-fit: cover;
     object-fit: cover;
  box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.25);
  border-radius: 4px;
}

h2 {
  background: linear-gradient(267.28deg, #f1b048 -27.68%, #a36709 129.01%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
}

.App {
  display: flex;
  width: 100%;
  height: 100vh;
}
.App aside {
  gap: 40px;
  width: 20%;
  padding: 40px 40px 40px 20px;
  display: flex;
  background: #ffffff;
  box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.25);
  flex-direction: column;
}
.App aside .logo {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  justify-content: flex-start;
}
.App aside .logo img {
  max-width: 50%;
  box-shadow: none;
}
.App aside .nav-links {
  flex: 1;
}
.App aside .nav-links a,
.App aside span {
  display: block;
  cursor: pointer;
  padding: 10px;
  color: #000;
}
.App aside .nav-links a.active {
  background: linear-gradient(267.28deg, #f1b048 -27.68%, #a36709 129.01%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
}
.App main {
  width: 80%;
  height: 100%;
  overflow-y: scroll;
}
.App main .wrapper {
  gap: 80px;
  padding: 60px;
  display: flex;
  flex-direction: column;
}
.App main .wrapper .exist {
  display: flex;
  flex-direction: column;
  gap: 25px;
}
.App main .wrapper .exist .imgs {
  background: #fff;
  box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.25);
  border-radius: 4px;
  display: flex;
  flex-direction: column;
  gap: 20px;
  padding: 30px;
}
.App main .wrapper .exist .imgs .img {
  gap: 30px;
  display: flex;
  flex-direction: column;
}
.App main .wrapper .exist .imgs .img:not(:first-of-type) {
  padding-top: 20px;
  border-top: 1px solid #ced4da;
}
.App main .wrapper .exist .imgs .img .container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 50px;
}
.App main .wrapper .exist .imgs .img .container .content {
  display: flex;
  gap: 30px;
  flex: 1;
  align-items: center;
}
.App main .wrapper .exist .imgs .img .container .content img {
  max-width: 120px;
}
.App main .wrapper .exist .imgs .img .container .control-btn {
  display: flex;
  gap: 10px;
}
.App main .wrapper .exist .imgs .img .container .control-btn .edit,
.App main .wrapper .exist .imgs .img .container .control-btn .delete {
  box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.25);
  color: #fff;
  border: none;
  padding: 0 20px;
  border-radius: 4px;
  cursor: pointer;
  height: -moz-fit-content;
  height: fit-content;
}
.App main .wrapper .exist .imgs .img .container .control-btn .edit {
  background: rgb(8, 197, 8);
}
.App main .wrapper .exist .imgs .img .container .control-btn .edit:hover {
  background: rgba(8, 197, 8, 0.8);
}
.App main .wrapper .exist .imgs .img .container .control-btn .delete {
  background: rgb(231, 9, 9);
}
.App main .wrapper .exist .imgs .img .container .control-btn .delete:hover {
  background: rgba(231, 9, 9, 0.8);
}
.App main .wrapper .exist .imgs .img form {
  flex-direction: column;
  gap: 20px;
  display: none;
}
.App main .wrapper .exist .imgs .img form.active {
  display: flex;
}
.App main .wrapper .exist .imgs .img form .file_feild {
  width: 100%;
  height: 50px;
  border-radius: 10px;
  padding: 0 14px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: #e6e6e6;
  position: relative;
}
.App main .wrapper .exist .imgs .img form .file_feild input[type=file] {
  border: none;
  padding: 0;
  height: 40px;
  padding-top: 8px;
  width: 240px;
  background: none;
}
.App main .wrapper .exist .imgs .img form .file_feild input[type=file]::-webkit-file-upload-button {
  cursor: pointer;
  border: none;
  background-color: #c4c4c4;
  padding: 6px;
  color: #000000;
  font-size: 14px;
}
.App main .wrapper .add-new {
  display: flex;
  flex-direction: column;
  gap: 25px;
}
.App main .wrapper .add-new form {
  background: #fff;
  box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.25);
  border-radius: 4px;
  display: flex;
  flex-direction: column;
  gap: 20px;
  padding: 30px;
}
.App main .wrapper .add-new form .file_feild {
  width: 100%;
  height: 50px;
  border-radius: 10px;
  padding: 0 14px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: #e6e6e6;
  position: relative;
}
.App main .wrapper .add-new form .file_feild input[type=file] {
  border: none;
  padding: 0;
  height: 40px;
  padding-top: 8px;
  width: 240px;
  background: none;
}
.App main .wrapper .add-new form .file_feild input[type=file]::-webkit-file-upload-button {
  cursor: pointer;
  border: none;
  background-color: #c4c4c4;
  padding: 6px;
  color: #000000;
  font-size: 14px;
}
.App main .wrapper .servUl li {
  display: flex;
  gap: 10px;
}
.App main .wrapper .inputs-group {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 30px;
  row-gap: 20px;
}

.lable {
  text-transform: uppercase;
  color: rgba(13, 27, 62, 0.7);
  font-weight: 700;
  font-size: 0.88rem;
  margin-bottom: 10px;
}

.input-row {
  display: flex;
  gap: 15px;
}

textarea,
input {
  display: block;
  width: 100%;
  min-height: calc(2.25rem + 2px);
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  border-radius: 4px;
  outline: none;
}

.c_img {
  width: 150px;
  height: 150px;
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.25);
  border-radius: 10px;
}
.c_img img {
  max-width: 100px !important;
  box-shadow: none;
  -o-object-fit: contain;
     object-fit: contain;
}

.login {
  width: 100%;
  direction: ltr;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 50px;
  flex-direction: column;
}
.login h1 {
  background: linear-gradient(267.28deg, #f1b048 -27.68%, #a36709 129.01%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  font-size: 2.7rem;
  line-height: 100px;
  width: 100%;
  text-align: center;
}
.login form {
  width: 40%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: 30px;
}
.login form button {
  width: 200px;
  height: 45px;
  margin-top: 20px;
  background: linear-gradient(268.08deg, #f1b048 0%, #a36709 100%);
  outline: none;
  cursor: pointer;
  color: #ffffff;
  font-size: 20px;
  border: none;
  border-radius: 10px;
}

table thead tr th,
td {
  text-align: center;
}

table thead tr th:nth-child(1) {
  text-align: inherit;
}

table tr {
  padding: 10px 0;
  height: 60px;
}
table tr a {
  color: #2376da;
}

.del {
  box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.25);
  color: #fff;
  border: none;
  padding: 0 10px;
  border-radius: 4px;
  cursor: pointer;
  height: -moz-fit-content;
  height: fit-content;
  background: rgb(231, 9, 9);
}
.del:hover {
  background: rgba(231, 9, 9, 0.8);
}

ol li {
  margin-bottom: 5px;
}
ol li a {
  color: #000;
}/*# sourceMappingURL=App.css.map */